import React from 'react'

import DeepDecision from '../../assets/deep-decision.svg'
import Header from '../header'

const Hero: React.FC = () => {
  return (
    <div className="dl-hero">
      <Header />
      <div className="dl-hero__container">
        <div className="dl-hero__container-image">
          <img src={DeepDecision} alt="DeepDecision" />
        </div>
        <div className="dl-hero__container-description">
        Schedule a call to learn how you can leverage Deep Labs to power context-aware decisions
        </div>
        <div className="dl-hero__container-contact">Schedule a call</div>
      </div>
    </div>
  )
}

export default Hero
