import { ColProps } from 'antd'
import React from 'react'

import { initialDataType } from '../../App'
import CustomIcon from '../../components/custom-icon'
import { Col, Row } from '../../components/grid'
import Radio, { Radio as RadioDL } from '../../components/radio'
import { Tooltip } from '../../components/tooltip'

const CardCol: React.FC<ColProps> = ({ children, ...props }) => {
  return (
    <Col {...props} xs={24} md={8}>
      {children}
    </Col>
  )
}

interface DataCardProps {
  data: initialDataType
  setData: (field: string, newData: string | number | boolean) => void
}

export const DataCard: React.FC<DataCardProps> = ({ data, setData }) => {
  return (
    <div className="dl-card-data">
      <Row gutter={0} className="dl-card-data__title">
        <Col>
          <h4>Data</h4>
        </Col>
      </Row>
      <Row gutter={[32, 32]} className="dl-card-data__body">
        <CardCol>
          <div style={{ width: '100%' }}>
            <div className="dl-card-data__body-option-title">
              <Tooltip title="Lorem Ipsum" placement="right">
                <div className="dl-card-data__body-option-title-content">
                  <h5>Select dataset</h5>
                  <CustomIcon type="InfoCircleOutlined" />
                </div>
              </Tooltip>
            </div>
            <div className="dl-card-data__body-option-content">
              <Radio.Group
                value={data.dataset}
                onChange={(e) => {
                  setData('dataset', e.target.value)
                }}
              >
                <Row
                  gutter={0}
                  className="dl-card-data__body-option-content-radio"
                >
                  <Col sm={24} md={12}>
                    <RadioDL
                      value="account-data"
                      label="Account Data (coming soon)"
                      enabled={false}
                    />
                  </Col>
                  <Col sm={24} md={12}>
                    <RadioDL
                      value="transaction-data"
                      label="Transaction Data"
                    />
                  </Col>
                </Row>
              </Radio.Group>
            </div>
          </div>
        </CardCol>
      </Row>
    </div>
  )
}
