import { Switch as AntdSwitch } from 'antd'
import {
  SwitchChangeEventHandler,
  SwitchClickEventHandler,
} from 'antd/es/switch'
import React from 'react'

interface SwitchProps {
  checked?: boolean
  enabled?: boolean
  loading?: boolean
  checkedChildren?: string
  unCheckedChildren?: string
  size?: 'default' | 'small'
  onChange?: SwitchChangeEventHandler
  onClick?: SwitchClickEventHandler
}

export const Switch: React.FC<SwitchProps> = ({
  checked = false,
  enabled = true,
  loading = false,
  checkedChildren = null,
  unCheckedChildren = null,
  size = 'default',
  onChange = undefined,
  onClick = undefined,
  ...rest
}) => {
  return (
    <div className="dl-switch">
      <AntdSwitch
        checked={checked}
        disabled={!enabled}
        loading={loading}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        size={size}
        onChange={onChange}
        onClick={onClick}
        {...rest}
      />
    </div>
  )
}
