import React, { useState, useEffect } from 'react'

import { initialDataType } from '../../App'
import ModalImg from '../../assets/img.png'
import { Button } from '../../components/button'
import CustomIcon from '../../components/custom-icon'
import { Col, Row } from '../../components/grid'
import { Modal } from '../../components/modal'
import { Switch } from '../../components/switch'
import { Tooltip } from '../../components/tooltip'

interface FeaturesCardProps {
  data: initialDataType
  setData: (data: initialDataType) => void
}

export const FeaturesCard: React.FC<FeaturesCardProps> = ({
  data,
  setData,
}) => {
  const [dlGraph, setDlGraph] = useState<boolean>(data.network)
  const [modalVisible, setModalVisible] = useState<string | null>(null)
  const [enableToggle, setEnableToggle] = useState<boolean>(false)

  const changeData = (newData: Partial<initialDataType>) => {
    setData({
      ...data,
      ...newData,
    })
  }

  const toggleGraph = () => {
    setDlGraph(!dlGraph)
    changeData({network: !dlGraph})
  }

  const resetContextualLayers = () => {
    changeData({ contextPsy1: false, contextPsy2: false, contextSitu1: false, contextSitu2: false, contextSitu3: false })
  }
  React.useEffect(() => {
    let toggleCount = 0
    const { rawAmount, rawBalance, rawMerchant, rawTime, rawZip } = data
    const rawToggles = { rawAmount, rawMerchant, rawTime, rawZip, rawBalance }
    Object.values(rawToggles).forEach(key => {if (key === true) toggleCount++ })

    if (toggleCount >= 4) {
      setEnableToggle(true)
    } else {
      setEnableToggle(false)
    }

  }, [data])

  useEffect(() => {
    if(!enableToggle) {
      resetContextualLayers()
    }
  }, [enableToggle])

  const toggleModal = (type?: string) => {
    if (modalVisible) setModalVisible(null)
    else if (type) setModalVisible(type)
  }
  return (
    <>
      <div className="dl-card-features">
        <Row
          justify="space-between"
          gutter={0}
          className="dl-card-features__title"
        >
          <Col>
            <h4>Features</h4>
          </Col>
          <Col className="dl-card-features__title-status">
            <div className="dl-card-features__title-status-icon">
              <div id="active" />
              Active features
            </div>
            <div className="dl-card-features__title-status-icon">
              <i id="inactive" />
              Inactive features
            </div>
          </Col>
        </Row>
        <Row gutter={0} className="dl-card-features__body">
          <Col xs={24} md={12}>
            <h5>Featurization</h5>
            <span className="dl-card-features__body-footnote">
              Add a minimum of 4 raw features (engineered features will
              auto-populate based on raw feature selection)
            </span>
            <Row gutter={40} className="dl-card-features__body-features">
              <Col span={12}>
                <div className="dl-card-features__body-features-container">
                  <Tooltip title="Raw features come directly from client data and are the core building blocks of the model">
                    <div className="dl-card-features__body-features-title-container">
                      <span className="dl-card-features__body-features-title">
                        Raw features
                      </span>
                      <CustomIcon type="InfoCircleOutlined" />
                    </div>
                  </Tooltip>
                  <div className="dl-card-features__body-features-poc">
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Merchant</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawMerchant']}
                        onChange={() =>
                          changeData({rawMerchant: !data['rawMerchant']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Amount</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawAmount']}
                        onChange={() =>
                          changeData({rawAmount: !data['rawAmount']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Time</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawTime']}
                        onChange={() => changeData({rawTime: !data['rawTime']})}
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Zip Code</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawZip']}
                        onChange={() => changeData({rawZip: !data['rawZip']})}
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Account Balance</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawBalance']}
                        onChange={() =>
                          changeData({rawBalance: !data['rawBalance']})
                        }
                      />
                    </div>
                  </div>
                  <div className="dl-card-features__body-features-info">
                    <CustomIcon type="SettingOutlined" />
                    <Button
                      type="link"
                      value={<>Learn how DeepDecision<sup>®</sup> uses raw features</>}
                      onClick={() => toggleModal('raw')}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="dl-card-features__body-features-container" >
                  <Tooltip title="Engineered features are created by transforming and combining raw features">
                    <div className="dl-card-features__body-features-title-container">
                      <span className="dl-card-features__body-features-title">
                        Engineered features
                      </span>
                      <CustomIcon type="InfoCircleOutlined" />
                    </div>
                  </Tooltip>
                  <div className="dl-card-features__body-features-poc">
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Merchant Network</span>
                      <Switch
                        enabled={false}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawMerchant']}
                        onChange={() =>
                          changeData({engineeredTest: !data['engineeredTest']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Transaction Velocity</span>
                      <Switch
                        enabled={false}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawAmount']}
                        onChange={() =>
                          changeData({engineeredVelocity: !data['engineeredVelocity']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Advanced Time Series</span>
                      <Switch
                        enabled={false}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawTime']}
                        onChange={() =>
                          changeData({engineeredZip: !data['engineeredZip']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Zip Frequency Encoded</span>
                      <Switch
                        enabled={false}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawZip']}
                        onChange={() =>
                          changeData({engineeredGeo: !data['engineeredGeo']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Latent Customer Network</span>
                      <Switch
                        enabled={false}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['rawBalance']}
                        onChange={() =>
                          changeData({engineeredNpl: !data['engineeredNpl']})
                        }
                      />
                    </div>
                  </div>
                  <div className="dl-card-features__body-features-info">
                    <CustomIcon type="ApartmentOutlined" />
                    <Button
                      type="link"
                      value={<>Learn how DeepDecision<sup>®</sup> uses engineered features</>}
                      onClick={() => toggleModal('engineered')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <h5>Contextualization</h5>
            <span className="dl-card-features__body-footnote">
              Add or remove the contextual layers you want to feed in by
              clicking on them
            </span>
            <Row gutter={40} className="dl-card-features__body-features">
              <Col span={12}>
                <div className="dl-card-features__body-features-container" >
                  <Tooltip title={<>Contextual layers are proprietary signals that DeepDecision<sup>®</sup> uses to enrich the data and extract insights</>}>
                    <div className="dl-card-features__body-features-title-container">
                      <span className="dl-card-features__body-features-title">
                        Contextual layers
                      </span>
                      <CustomIcon type="InfoCircleOutlined" />
                    </div>
                  </Tooltip>
                  <div className="dl-card-features__body-features-poc">
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Psychographics</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['contextPsy1']}
                        onChange={() =>
                          changeData({contextPsy1: !data['contextPsy1']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Geolocation</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['contextPsy2']}
                        onChange={() =>
                          changeData({contextPsy2: !data['contextPsy2']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Sentiment</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['contextSitu1']}
                        onChange={() =>
                          changeData({contextSitu1: !data['contextSitu1']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Weather</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['contextSitu2']}
                        onChange={() =>
                          changeData({contextSitu2: !data['contextSitu2']})
                        }
                      />
                    </div>
                    <div className="dl-card-features__body-features-poc-switch">
                      <span>Event</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={data['contextSitu3']}
                        onChange={() =>
                          changeData({contextSitu3: !data['contextSitu3']})
                        }
                      />
                    </div>
                  </div>
                  <div className="dl-card-features__body-features-info">
                    <CustomIcon type="BuildOutlined" />
                    <Button
                      type="link"
                      value={<>Learn how DeepDecision<sup>®</sup> uses contextual layers</>}
                      onClick={() => toggleModal('contextual')}
                    />
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="dl-card-features__body-features-container">
                  <Tooltip title={<>DeepDecision<sup>®</sup> has its own proprietary graph embedding that improves model outcomes</>}>
                    <div className="dl-card-features__body-features-title-container">
                      <span className="dl-card-features__body-features-title">
                        Network graph
                      </span>
                      <CustomIcon type="InfoCircleOutlined" />
                    </div>
                  </Tooltip>
                  <div className="dl-card-features__body-features-network">
                    <div className="dl-card-features__body-features-network-switch">
                      <span>DeepDecision<sup>®</sup> Graph</span>
                      <Switch
                        enabled={enableToggle}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={dlGraph}
                        onChange={toggleGraph}
                      />
                    </div>
                    {/* // Temporarily disabled
                    <div className="dl-card-features__body-features-network-switch">
                      <span>Normal Graph</span>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        checked={!dlGraph}
                        onChange={toggleGraph}
                      />
                    </div> */}
                  </div>
                  <div className="dl-card-features__body-features-info">
                    <CustomIcon type="ProjectOutlined" />
                    <Button
                      type="link"
                      value={<>Learn how DeepDecision<sup>®</sup> uses network graph</>}
                      onClick={() => toggleModal('network')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalVisible === 'raw'}
        title="Raw features"
        onOk={() => null}
        onCancel={toggleModal}
        image={ModalImg}
        imageAlt="Modal Alt"
        okText="Learn more"
        cancelText={null}
        content={
          <>
            <p className="dl-card-features__modal-text">
              Raw features generally depict data about transactions and account holders that a company already has access to.
              Deep Labs selects the raw features that are the building blocks for constructing predictive features to develop AI models.
              Experience has shown that models built on raw features alone tend to suffer from low robustness and generalizability over time.
            </p>
          </>
        }
      />
      <Modal
        visible={modalVisible === 'engineered'}
        title="Engineered features"
        onOk={() => null}
        onCancel={toggleModal}
        image={ModalImg}
        imageAlt="Modal Alt"
        okText="Learn more"
        cancelText={null}
        content={
          <>
            <p className="dl-card-features__modal-text">
              Leveraging Deep Labs expertise and research, we have identified important feature patterns that will increase the signal value from raw features through automated feature engineering.
            </p>
          </>
        }
      />
      <Modal
        visible={modalVisible === 'contextual'}
        title="Contextual layers"
        onOk={() => null}
        onCancel={toggleModal}
        image={ModalImg}
        imageAlt="Modal Alt"
        okText="Learn more"
        cancelText={null}
        content={
          <>
            <p className="dl-card-features__modal-text">
            Deep Labs proprietary contextual system interweaves contextual information with other available features in order to build contextually-aware predictive signals that can be used by downstream AI models for building more robust AI systems.
            </p>
          </>
        }
      />
      <Modal
        visible={modalVisible === 'network'}
        title="Network graph"
        onOk={() => null}
        onCancel={toggleModal}
        image={ModalImg}
        imageAlt="Modal Alt"
        okText="Learn more"
        cancelText={null}
        content={
          <>
            <p className="dl-card-features__modal-text">
              Reflects the inherit inter-related nature of our world by identifying the latent relationships of various participants in the payment space.
              The relationship links enable construction of highly predictive signals that otherwise would not have been available in tabular data.
            </p>
          </>
        }
      />
    </>
  )
}
