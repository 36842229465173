/* eslint-disable @typescript-eslint/ban-ts-comment,prefer-const */
// @ts-nocheck
import { Col as AntdCol } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import './App.scss'
import { AccountCard } from './cards/account'
import { DataCard } from './cards/data'
import { FeaturesCard } from './cards/features'
import { FooterCard } from './cards/footer'
import { NavigationCard } from './cards/navigation'
import { BottomBar } from './components/bottom-bar'
import { Footer } from './components/footer'
import { Col, Row } from './components/grid'
import Hero from './components/hero'
import embeddingCoordinates from './data/embedding-coordinates.json'
import selectedFeaturesToChartDataset from './data/selected-features-to-chart-dataset.json'
import useSticky from './lib/useSticky'
import { useStore } from './state'

const initialData = {
  // Data
  dataset: 'transaction-data',
  noise: 10,
  size: 10,
  network: true,

  // Features
  rawMerchant: true,
  rawAmount: true,
  rawTime: true,
  rawZip: true,
  rawBalance: false,

  engineeredTest: false,
  engineeredVelocity: false,
  engineeredZip: false,
  engineeredGeo: false,
  engineeredNpl: false,

  contextPsy1: false,
  contextPsy2: false,
  contextSitu1: false,
  contextSitu2: false,
  contextSitu3: false,
}

const convertSelectedFeaturesToKey = ({
  rawMerchant,
  rawAmount,
  rawTime,
  rawZip,
  rawBalance,
  engineeredTest,
  engineeredVelocity,
  engineeredZip,
  engineeredGeo,
  contextNpl,
  contextPsy1,
  contextPsy2,
  contextSitu1,
  contextSitu2,
  contextSitu3
}) => {
  return [
    rawMerchant,
    rawAmount,
    rawTime,
    rawZip,
    rawBalance,
    engineeredTest,
    engineeredVelocity,
    engineeredZip,
    engineeredGeo,
    contextNpl,
    contextPsy1,
    contextPsy2,
    contextSitu1,
    contextSitu2,
    contextSitu3
  ].map(feature => feature? 1 : 0).join('')
}

const mapSelectedFeaturesKeyToChartData = (selectedFeaturesKey: string) => {
  return embeddingCoordinates[selectedFeaturesToChartDataset[selectedFeaturesKey]]
}

export type initialDataType = typeof initialData
export type chartDataPointType = [number, number, number, number, string, number, number]

function App() {
  const { sticky, stickyRef } = useSticky()
  const [data, setData] = useState<initialDataType>(initialData)
  const [oldData, setOldData] = useState<initialDataType>(initialData)
  const [bottomBar, setBottomBar] = useState<boolean>(false)
  const [backdrop, setBackdrop] = useState<boolean>(false)
  const [chartData, setChartData] = useState<number[]>([])

  const changeData = (field: string, newData: string | number | boolean) => {
    setData({
      ...data,
      [field]: newData,
    })
  }

  const problem = useStore((state) => state.problem)
  const dataset = useStore((state) => state.dataset)
  const regDataset = useStore((state) => state.regDataset)
  const percTrainData = useStore((state) => state.percTrainData)
  const activation = useStore((state) => state.activation)
  const regularization = useStore((state) => state.regularization)

  const inputs = useStore((state) => state.inputs)
  const setInputs = useStore((state) => state.setInputs)

  const isPlaying = useStore((state) => state.isPlaying)
  const iter = useStore((state) => state.iter)
  const lossTrain = useStore((state) => state.lossTrain)
  const lossTest = useStore((state) => state.lossTest)
  const oneStep = useStore((state) => state.oneStep)
  const resetNetwork = useStore((state) => state.resetNetwork)

  useEffect(() => {
    resetNetwork()
  }, [
    inputs,
    problem,
    dataset,
    regDataset,
    data.noise,
    percTrainData,
    activation,
    regularization,
  ])

  useEffect(() => {
    let t: NodeJS.Timeout | undefined
    if (!t) {
      t = setInterval(() => {
        if (isPlaying) {
          oneStep(iter)
        }
      }, 100)
    }
    return () => clearInterval(t)
  }, [isPlaying])

  const [, setLossTests] = useState([])
  const [, setLossTrains] = useState([])
  useEffect(() => {
    const testDatum = { x: iter, y: lossTest }
    const trainDatum = { x: iter, y: lossTrain }
    if (iter === 0) {
      // @ts-ignore
      setLossTests([testDatum])
      // @ts-ignore
      setLossTrains([trainDatum])
    } else {
      // @ts-ignore
      setLossTests(addLoss(testDatum))
      // @ts-ignore
      setLossTrains(addLoss(trainDatum))
    }
  }, [iter, lossTest, lossTrain])

  const updateChart = () => {
    const chartData = mapSelectedFeaturesKeyToChartData(convertSelectedFeaturesToKey(data))
    setChartData(chartData)
  }

  const ok = () => {
    Object.keys(data).map((k) => {
      if (k.startsWith('raw-')) setInputs(k.split('-')[1], data[k])
    })
    setOldData(data)

    updateChart()
  }

  useEffect(() => {
    if (JSON.stringify(data) != JSON.stringify(oldData)) setBottomBar(true)
    else setBottomBar(false)
  }, [data, oldData])

  useEffect(() => {
    updateChart()
  }, [])

  return (
    <>
      <div
        className="dl-app"
        style={backdrop ? { height: '100vh', overflow: 'hidden' } : {}}
      >
        <Row>
          <Col xs={24} >
            <Hero />
          </Col>
        </Row>
        <Row justify="center">
          <AntdCol xs={24} ref={stickyRef}>
            <NavigationCard sticky={sticky} stickyRef={stickyRef} />
          </AntdCol>
        </Row>
        <Row justify="center">
          <Col xs={24} >
            <DataCard data={data} setData={changeData} />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} >
            <FeaturesCard data={data} setData={setData} />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} >
            <AccountCard data={chartData}/>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} >
            <FooterCard />
          </Col>
        </Row>
      </div>
      <BottomBar
        visible={bottomBar}
        backdrop={backdrop}
        setBackdrop={setBackdrop}
        onOk={ok}
        onCancel={() => {
          setData(oldData)
        }}
      />
      <Footer />
    </>
  )
}

export default App
