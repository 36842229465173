import React from 'react'

import Image from '../../assets/bottom-img.png'
import { Button } from '../button'
import CustomIcon from '../custom-icon'


export const Footer = () => {
  return (
    <div className="dl-footer">
      <div className="dl-footer__main">
        <div className="dl-footer__main-title">
          <h1>Want to learn more?</h1>
        </div>
        <div className="dl-footer__main-button">
          <Button
            value="Contact us"
            type="ghost"
            color="secondary"
          />
        </div>
        <div className="dl-footer__main-image">
          <img src={Image} alt="bottom image" />
        </div>
      </div>
      <div className="dl-footer__bottom-bar">
        <div className="dl-footer__bottom-bar-copyrights">
          <h5>&copy; 2022 DEEPLABS | ALL RIGHTS RESERVED</h5>
        </div>
        <div className="dl-footer__bottom-bar-right">
          <div className="dl-footer__bottom-bar-right-privacy">
            <a href="#">Privacy Policy</a>
          </div>
          <div className="dl-footer__bottom-bar-right-separator">
          </div>
          <div className="dl-footer__bottom-bar-right-icons">
            <a href="#"><CustomIcon type="TwitterOutlined" /></a>
            <a href="#"><CustomIcon type="LinkedinFilled" /></a>
          </div>
        </div>
      </div>
    </div>
  )
}
