import React from 'react'

import { chartDataPointType } from '../../App'
import Scatter3D from '../../components/graph'
import { Col, Row } from '../../components/grid'
// import { Select } from '../../components/select'

interface AccountCardProps {
  data: undefined | null | chartDataPointType[]
}

export const AccountCard: React.FC<AccountCardProps> = ({ data }) => {
  return (
    <div className="dl-card-account">
      <Row gutter={0} className="dl-card-account__title">
        <Col>
          <h4>Transaction Monitoring</h4>
        </Col>
      </Row>
      <Row gutter={32} className="dl-card-account__body">
        {/* 
        // Grid columns altered for MVP
        <Col xs={24} md={17}> 
        */}
        <Col xs={24}>
          <div style={{ width: '100%' }}>
            {/* setting data prop to undefined until data is complete */}
            <Scatter3D data={data} />
          </div>
        </Col>
        {/* 
        // Side panel removed for MVP
        <Col xs={24} md={7}>
          <div className="dl-card-account__body-right-panel">
            <h4>
              Reduce false declines and increase payment authorization rates by
              incorporating behavioral insights and contextual awareness
            </h4>
            <div className="dl-card-account__body-right-panel-select">
              <h5>Filter By</h5>
              <Select
                options={[
                  {
                    value: 'fraud',
                    key: 'fraud',
                    label: 'Fraud / Non-Fraud',
                  },
                ]}
                defaultValue="fraud"
                size="middle"
                width="100%"
              />
              <div className="dl-card-account__body-right-panel-select-status">
                <div className="dl-card-account__body-right-panel-select-status-icon">
                  <div id="fraud" />
                  Fraud
                </div>
                <div className="dl-card-account__body-right-panel-select-status-icon">
                  <i id="non-fraud" />
                  Non-fraud
                </div>
              </div>
            </div>
          </div>
        </Col> 
        */}
      </Row>
    </div>
  )
}
