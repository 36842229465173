import { Col as AntdCol, ColProps, Row as AntdRow } from 'antd'
import React from 'react'

interface BaseProps {
  children?: React.ReactNode
}

interface RowProps extends BaseProps {
  gutter?: number | [number, number]
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  align?: 'top' | 'middle' | 'bottom'
  className?: string
}

export const Row: React.FC<RowProps> = ({
  children,
  gutter = 16,
  justify = 'start',
  align = 'top',
  className,
}) => {
  return (
    <AntdRow
      gutter={gutter}
      justify={justify}
      align={align}
      className={className}
    >
      {children}
    </AntdRow>
  )
}

export const Col: React.FC<ColProps> = ({ children, ...props }) => {
  return <AntdCol {...props}>{children}</AntdCol>
}
