import { Radio as AntdRadio } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import React from 'react'

interface RadioProps {
  children?: React.ReactNode
  checked?: boolean
  enabled?: boolean
  label?: string
  value: string
  onChange?: (e: RadioChangeEvent) => void
}

export const Radio: React.FC<RadioProps> = ({
  children,
  checked = false,
  enabled = true,
  label = null,
  value,
  onChange = undefined,
  ...rest
}) => {
  return (
    <div className="dl-radio">
      <AntdRadio
        checked={checked}
        disabled={!enabled}
        onChange={onChange}
        value={value}
        {...rest}
      >
        {label && label.length > 0 ? (
          <span className="dl-radio__label">{label}</span>
        ) : (
          children
        )}
      </AntdRadio>
    </div>
  )
}

export default AntdRadio
