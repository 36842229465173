import { Tooltip as AntdTooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'

interface TooltipProps {
  children: React.ReactNode
  title: string | React.ReactNode
  placement?: TooltipPlacement
  color?: string
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  placement = 'top',
  color,
}) => {
  return (
    <div className="dl-tooltip">
      <AntdTooltip title={title} placement={placement} color={color}>
        {children}
      </AntdTooltip>
    </div>
  )
}
