import { Modal as AntdModal } from 'antd'
import React from 'react'

import { Button } from '../button'

interface ModalProps {
  visible: boolean
  title: string
  onOk: () => void
  okText?: string
  onCancel?: () => void
  cancelText?: string | null
  image?: string
  imageAlt?: string
  content: React.ReactNode
  width?: number
}

export const Modal: React.FC<ModalProps> = ({
  visible = false,
  title,
  onOk,
  okText = 'Ok',
  onCancel,
  cancelText = 'Cancel',
  image,
  imageAlt,
  content,
  width = 572,
}) => {
  return (
    <div className="dl-modal">
      <AntdModal
        visible={visible}
        title={title}
        onOk={onOk}
        okText={okText}
        onCancel={onCancel}
        cancelText={cancelText}
        width={width}
        className="dl-modal"
        footer={[
          cancelText && (
            <Button
              key="back"
              value={cancelText}
              onClick={onCancel}
              type="ghost"
            />
          ),
          // <div key="spacer" style={{ marginLeft: 16 }}></div>,
          // <Button key="submit" value={okText} onClick={onOk} type="default" />,
        ]}
      >
        {image && (
          <div className="dl-modal__content-image">
            <img src={image} alt={imageAlt} />
          </div>
        )}
        {content}
      </AntdModal>
    </div>
  )
}
