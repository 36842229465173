/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as AntdIcons from '@ant-design/icons'
import React from 'react'

interface CustomIconProps {
  type?: string
}

const CustomIcon: React.FC<CustomIconProps> = ({ type }) => {
  // @ts-ignore
  const AntdIcon = AntdIcons[type]
  if (!type || !AntdIcon) return <></>
  return <AntdIcon />
}

export default CustomIcon
