// Custom useWindowWidth hook to read size of
// screen on resize. This hook is removed when
// component using it is unmounted.
// https://stackoverflow.com/a/36862446/2467292
import { useEffect, useState } from 'react'

function getWindowWidth() {
  const { innerWidth: width } = window
  return width
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth())

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}

export const mobileWidth = 768