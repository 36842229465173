import React from 'react'

import DeepLogo from '../../assets/logotype.svg'
import { Button } from '../button'
import MobileDrawer from '../mobile-drawer'

const Header: React.FC = () => {
  return (
    <div className="dl-header">
      <div className="dl-header__logo">
        <img src={DeepLogo} alt="DeepDecision<sup>®</sup> logo" />
      </div>
      <div  className="dl-header__right">
        <a
          href="#"
        >
          Return to website
        </a>
        <Button
          type="default"
          value="Documentation"
        />
      </div>
      <MobileDrawer/>
    </div>
  )
}

export default Header
