import { FC } from 'react'
import Plot from 'react-plotly.js'

import { chartDataPointType } from '../../App'

interface Props {
  data: undefined | null | chartDataPointType[]
}

const Scatter3D: FC<Props> = ({ data }) => {
  const modeBarButtonsToRemove: Plotly.ModeBarDefaultButtons[] = [
    'toImage',
    'orbitRotation',
    'resetCameraLastSave3d',
  ]

  function buildDataForPlot(): Plotly.Data[] {
    let newElement: Plotly.Data[]

    if (
      data === null ||
      data === undefined ||
      (Array.isArray(data) && !data.length)
    ) {
      // No data is selected so ensure scatter plot is empty
      newElement = [
        {
          x: [],
          y: [],
          z: [],
          type: 'scatter3d',
        },
      ]

      return newElement
    }

    const x: number[] = []
    const y: number[] = []
    const z: number[] = []
    const color: (string|number)[] = []
    const customdata: [string, number, number][] = []

    data.forEach((element: chartDataPointType) => {
      // data is an array of arrays with each transaction being represented by
      // array starts with three numbers representing x, y and z coordinates
      x.push(element[0])
      y.push(element[1])
      z.push(element[2])

      // array then contains color value of 0 or 1 (0 = blue (also default) and 1 = red)
      color.push((element[3] === 1) ? '#FF0000' : 0)

      // array then contains values for the tooltip in this order: merchant, amount, dispute_risk
      const thisCustomData: [string, number, number] = [element[4], element[5], element[6]]
      customdata.push(thisCustomData)
    })

    newElement = [
      {
        x,
        y,
        z,
        customdata,
        hovertemplate:
        'Merchant:&#8195;&#8195;&#8201;&#8201;<b>%{customdata[0]}</b><br>' +
        'Amount:&#8195;&#8195;&#8195;<b>$%{customdata[1]}</b><br>' +
        'Dispute Risk:&#8195;<b>%{customdata[2]}</b><br>' +
        '<extra></extra>',
        mode: 'markers',
        marker: {
          size: 5,
          color,
          colorscale: 'Blues',
          line: {
            width: 0,
          },
          opacity: 1,
        },
        type: 'scatter3d',
      },
    ]

    return newElement
  }

  // Leaving this comment for potential improvement of data handling (depending on how data is managed going in).
  // No need to use this for MVP - can keep things more as-is.

  // Using useEffect, on first run the graph could format the full dataset, creating a new object to go in the
  // 'newElement' array for each 'iteration' (0 through 22), with the x/y/x for all 2000 rows.
  // Then, instead of passing a data object into the graph as is currently done, there is potential to pass through
  // which 'iterations' to show in the graph. When the prop for the iterations to show changes, it reruns useEffect,
  // but instead of building a whole new dataset, select the right iteration object to pass into the actual plot.

  return (
    <Plot
      data={buildDataForPlot()}
      layout={{
        showlegend: false,
        autosize: true,
        margin: {
          t: 0,
          r: 0,
          b: 0,
          l: 0,
        },
        scene: {
          xaxis: {
            showspikes: false,
            showgrid: false,
            visible: false,
            showticklabels: false,
          },
          yaxis: {
            showspikes: false,
            showgrid: false,
            visible: false,
            showticklabels: false,
          },
          zaxis: {
            showspikes: false,
            showgrid: false,
            visible: false,
            showticklabels: false,
          },
          camera: {
            center: {
              x: 0.002127856288940447,
              y: 0.014921535627814715,
              z: -0.017049391916755162,
            },
            eye: {
              x: 0.7808307412333486,
              y: 0.7936244205722228,
              z: 0.7616534930276528,
            },
            up: {
              x: 0,
              y: 0,
              z: 1,
            },
          },
        },
        hoverlabel: {
          align: 'left',
        },
        uirevision: 'true',
        plot_bgcolor: '#040020',
        paper_bgcolor: '#040020',
      }}
      style={{
        height: '450px', // when set at 100% the page footer encroaches on it's space when page loads
        width: '100%',
      }}
      config={{
        modeBarButtonsToRemove,
        displaylogo: false,
        responsive: true,
      }}
    />
  )
}

export default Scatter3D
