import { Button as AntdButton } from 'antd'
import React, { ReactNode } from 'react'

import CustomIcon from '../custom-icon'

interface ButtonProps {
  icon?: string
  value?: string | ReactNode
  type?: 'default' | 'ghost' | 'link'
  color?: 'primary' | 'secondary'
  enabled?: boolean
  onClick?: () => void
  size?: 'large' | 'middle'
}

export const Button: React.FC<ButtonProps> = ({
  value,
  icon,
  type = 'default',
  color = 'primary',
  enabled = true,
  onClick,
  size = 'middle',
  ...rest
}) => {
  const hasValue = value

  return (
    <div className="dl-button">
      <AntdButton
        type={type}
        shape="round"
        className={`${color} ${!hasValue && icon ? 'only-icon' : ''}`}
        disabled={!enabled}
        onClick={onClick}
        size={size}
        {...rest}
      >
        <CustomIcon type={icon} />
        {hasValue && <span>{value}</span>}
      </AntdButton>
    </div>
  )
}
