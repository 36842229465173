import { Button, Drawer, Space } from 'antd'
import React, { useState } from 'react'

import BlackLogo from '../../assets/black-logo.svg'
import MobileMenu from '../../assets/mobile-menu.svg'

const MobileDrawer: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <div className="mobile-drawer-container">
      <Button type="text" onClick={showDrawer}>
        <img src={MobileMenu} alt="MobileMenu" />
      </Button>
      <Drawer
        extra={<Space>
          <img src={BlackLogo} alt="BlackLogo" />
        </Space>}
        className="drawer-container" placement="right" onClose={onClose} visible={visible}>
        <a
          href="#"
        >
          Return to website
        </a>
        <Button
          type="default"
        >
          Documentation
        </Button>
      </Drawer>
    </div>
  )
}

export default MobileDrawer