import { Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import React from 'react'

import Menu from '../../assets/menu.svg'
import { mobileWidth, useWindowWidth } from '../../lib'
import useSticky from '../../lib/useSticky'
import { dashboardLink } from '../../utils'

interface NavigationCardProps {
  sticky: boolean
  stickyRef: React.RefObject<HTMLDivElement>
}

export const NavigationCard: React.FC<NavigationCardProps> = ({ sticky, stickyRef }) => {
  const isMobile = useWindowWidth() <= mobileWidth

  const handleUrlChange = (app: string): void => {
    switch (app) {
      case 'dashboard':
        window.open(dashboardLink, '_self')
        break
      default:
        break
    }
  }

  return (
    <>
      <div
        className={sticky ? 'dl-nav dl-nav-sticky' : 'dl-nav'}
      >
        <div className="menu-container">
          <img src={Menu} alt="circle" />
          <Select
            className="deep-apps-select"
            defaultValue="playground"
            onChange={(value) => handleUrlChange(value)}
          >
            <Option value="playground">Playground</Option>
            <Option value="dashboard">Dashboard</Option>
          </Select>
        </div>
        <div className="dl-nav__right-text">
          <span>
            Understand how DeepDecision<sup>®</sup> contextualization enables better decision
            making
          </span>
        </div>
      </div>
      <div
        style={{
          height: (sticky && isMobile)
            ? `${stickyRef.current?.clientHeight}px`
            : 0
        }}
      />
    </>
  )
}
