import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

const antIcon = (size: number) => (
  <LoadingOutlined
    style={{
      fontSize: size,
      color: '#FFFFFF',
    }}
    spin
  />
)

interface LoadingProps {
  size: number
}
export const Loading: React.FC<LoadingProps> = ({ size }) => {
  return <Spin indicator={antIcon(size)} />
}
