import { useEffect, useRef, useState } from 'react'

const useSticky = () => {
  const stickyRef = useRef<HTMLDivElement>(null)
  const [sticky, setSticky] = useState(false)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (!stickyRef.current) {
      return
    }
    setOffset(stickyRef.current.offsetTop)
  }, [stickyRef, setOffset])

  useEffect(() => {
    let oldScroll = 0
    const handleScroll = () => {
      if (!stickyRef.current) {
        return
      }

      setSticky(window.scrollY > offset && oldScroll > window.scrollY)
      oldScroll = window.scrollY
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [setSticky, stickyRef, offset])
  return { stickyRef, sticky }
}

export default useSticky