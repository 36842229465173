import React from 'react'

import Circle from '../../assets/circle.png'
import { Button } from '../../components/button'
import { Row } from '../../components/grid'
import { dashboardLink } from '../../utils'

export const FooterCard = () => {
  return (
    <div className="dl-card-footer">
      <Row gutter={0} className="dl-card-footer__body">
        <img src={Circle} alt="circle" />
        <h4>
          Launch a simulation showing how you can integrate DeepDecision<sup>®</sup>&apos; s
          predictive signals into your existing dashboard environment to power
          context-aware decisions
        </h4>
        <div className="dl-card-footer__body-button">
          <Button
            value="Launch now"
            onClick={() => window.open(dashboardLink, '_self')}
          />
        </div>
      </Row>
    </div>
  )
}
