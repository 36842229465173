import React from 'react'

import { Loading } from '../../loading'
import { Button } from '../button'

interface BottomBarProps {
  visible?: boolean
  backdrop: boolean
  setBackdrop: React.Dispatch<React.SetStateAction<boolean>>
  onOk: () => void
  onCancel: () => void
}

export const BottomBar: React.FC<BottomBarProps> = ({
  visible = false,
  backdrop,
  setBackdrop,
  onOk,
  onCancel,
}) => {
  return (
    <>
      <div className={`dl-bottom-bar ${visible && 'visible'}`}>
        <Button value="Cancel" type="ghost" onClick={onCancel} />
        <Button
          value="Apply Changes"
          onClick={async () => {
            setBackdrop(true)
            await new Promise((r) => setTimeout(r, 2000))
            setBackdrop(false)
            onOk()
          }}
        />
      </div>
      <div className={`dl-backdrop ${backdrop && 'visible'}`}>
        <div className="dl-backdrop-content">
          <Loading size={64} />
          <span>Applying changes</span>
        </div>
      </div>
    </>
  )
}
